import { render, staticRenderFns } from "./recommendedItem.vue?vue&type=template&id=cacf9a1e&scoped=true"
import script from "./recommendedItem.vue?vue&type=script&lang=js"
export * from "./recommendedItem.vue?vue&type=script&lang=js"
import style0 from "./recommendedItem.vue?vue&type=style&index=0&id=cacf9a1e&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cacf9a1e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\main project\\new_front_melinked\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cacf9a1e')) {
      api.createRecord('cacf9a1e', component.options)
    } else {
      api.reload('cacf9a1e', component.options)
    }
    module.hot.accept("./recommendedItem.vue?vue&type=template&id=cacf9a1e&scoped=true", function () {
      api.rerender('cacf9a1e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/newVersion/pageViews/postContentDetails/detailsSidebar/recommendedItem.vue"
export default component.exports