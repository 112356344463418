<template>
  <div class="commentArea">
    <CommentInputBox
      :showAvatar="true"
      @triggerSubmit="triggerSubmit"
      :sendLoading="sendLoading"
      :withPictures="true"
    ></CommentInputBox>
    <!-- 嵌套这层主要是为了给tabbar加一条底边 -->
    <div class="tabbarContainer">
      <div class="commentTabbar" v-if="needShowTab">
        <Tabbar :tabbars="tabbars" @tabActive="tabActive"></Tabbar>
      </div>
      <div class="bottomBorderBox" v-if="needShowTab"></div>
    </div>
    <div class="tabbarContent" v-if="reloadFlag" ref="tabbarContent_body">
      <el-carousel
        :height="carouselHeight"
        :initial-index="tabIndex"
        indicator-position="none"
        arrow="never"
        :autoplay="false"
        :loop="false"
        ref="tabbarContent"
      >
        <el-carousel-item
          v-for="(item, index) in tabbars"
          :key="item.className"
        >
          <template v-if="tabbars[index].countText !== '0'">
            <div
              class="scrollContainer noScroll"
              v-if="!needScroll"
              v-scroll="{
                distance: 70,
                onBottom: loadMoreRecentCommunicationsData,
              }"
            >
              <div
                :is="item.componentName"
                :replyInTheComponent="true"
                :ref="item.componentName"
                :likeProviders="postInfo.likeProviders"
                :getCommentsParam="getCommentsParam"
                :showNewComment="true"
                @getCommentCount="getCommentCount"
                :openCommentInput="openCommentInput"
              ></div>
            </div>
            <!-- 这里是为了滚动逻辑所有写了个一样的在下面 -->
            <div
              class="scrollContainer canScroll"
              :style="{ opacity: needScroll ? 1 : 0 }"
              v-scroll="{
                distance: 70,
                onBottom: loadMoreRecentCommunicationsData,
              }"
            >
              <div
                :is="item.componentName"
                :replyInTheComponent="true"
                :ref="item.componentName"
                :likeProviders="postInfo.likeProviders"
                :getCommentsParam="getCommentsParam"
                :showNewComment="true"
                @getCommentCount="getCommentCount"
              ></div>
            </div>
          </template>
          <div class="placeholderMap" v-else>
            <PlaceholderMap :placeholderType="0"></PlaceholderMap>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>
<script>
import CommentInputBox from "../../../components/comment/commentInputBox";
import Tabbar from "../../../components/tabbar";
import CommentList from "../../../components/list/commentList";
import LikesList from "../../../components/list/likesList";
import PlaceholderMap from "../../../components/placeholderMap";
import { addNewReview } from "@/api/newVersion";
export default {
  components: {
    CommentInputBox,
    Tabbar,
    CommentList,
    LikesList,
    PlaceholderMap,
  },
  props: {
    postInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    needScroll: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // 打开第一条评论的输入框
    openCommentInput() {
      return this.$store.state.globalShare.showCommentInputInPostInfo;
    },
    // 帖子列表参数
    getCommentsParam() {
      return {
        // 操作对象ID
        id: this.postInfo.gigId,
        // 操作对象(1:旧版帖子评论,3:用户,4:群,5:公司,7:新版帖子评论)
        operation: 7,
        // 点赞用的type(1:帖子,3:用户,4:群)
        type: 1,
        // 这个参数表示更多评论是否用弹窗展示
        popupDisplay: true,
      };
    },
    // 判断是否需要展示tab内容
    needShowTab() {
      return (
        (this.tabbars[0] && this.tabbars[0].countText !== "0") ||
        (this.tabbars[1] && this.tabbars[1].countText !== "0")
      );
    },
    tabbars() {
      let tabbarsArr = [
        {
          className: "icon-information-fill",
          countText: this.postInfo.commentCount,
          componentName: "CommentList",
        },
        {
          className: "icon-fabulous-fill",
          countText: this.postInfo.likeCount,
          componentName: "LikesList",
        },
      ];
      return tabbarsArr;
    },
  },
  watch: {
    // 监听到评论数量有变化，刷新一下组件
    "postInfo.commentCount": {
      handler(val, old) {
        if (val !== old) {
          this.reloadFlag = false;
          this.$nextTick(() => {
            this.reloadFlag = true;
          });
        }
      },
      deep: true,
    },
    // 监听展示状态，以便重置tab页的展示的下标
    needShowTab: {
      handler(val, old) {
        if (val !== old) {
          this.tabActive(0);
        }
      },
      deep: true,
    },
  },
  mounted() {},
  data() {
    return {
      tabIndex: 0,
      carouselHeight: "100%",
      // 展示
      reloadFlag: true,
      sendLoading: false,
    };
  },
  methods: {
    // 获取评论总数
    getCommentCount(count) {
      this.postInfo.commentCount = count;
    },
    // 加载更多评论列表
    async loadMoreRecentCommunicationsData() {
      try {
        await this.$refs[
          this.tabbars[this.tabIndex].componentName
        ][0].getReplaysList();
      } catch (error) {}
    },
    // 发布评论后记录被评论的帖子Id
    saveCommentedGigid() {
      const beCommentedGigids = this.$store.state.globalShare.beCommentedGigids;
      beCommentedGigids.push(this.getCommentsParam.id);
      this.$store.commit("setBeCommentedGigids", beCommentedGigids);
    },
    // 回复评论触发
    async triggerSubmit(param) {
      this.sendLoading = true;
      let params = {
        operation: this.getCommentsParam.operation,
        reply: param.commentText,
      };
      if (param.imgUrl) {
        params.imgUrl = param.imgUrl;
      }
      let result = await addNewReview(this.getCommentsParam.id, params);
      this.sendLoading = false;
      if (result.code === 200) {
        this.saveCommentedGigid();
        this.postInfo.commentCount = (
          Number(this.postInfo.commentCount) + 1
        ).toString();
        // 刷新评论列表区
        this.$store.commit(
          "setNeedRefreshOperationComment",
          this.getCommentsParam.id
        );
        let latestComment = {
          commentId: this.postInfo.gigId,
          commentCount: this.postInfo.commentCount,
        };
        this.$store.dispatch("commitSetListOfLatestComments", latestComment);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 获取tabbar点击事件
    tabActive(tabIndex) {
      this.tabIndex = tabIndex;
      this.$refs.tabbarContent.setActiveItem(this.tabIndex);
    },
  },
};
</script>
<style lang="stylus" scoped>
.commentArea
  width 100%;
  margin-top 30px;
  overflow hidden;
  .tabbarContainer
    width 100%;
    margin-top 40px;
    display flex;
    align-items flex-start;
    .commentTabbar
      width 248px;
      height 45px;
      flex-shrink 0;
      position relative;
      box-sizing border-box;
    .bottomBorderBox
      flex 1;
      min-width 0;
      height 40px;
      box-sizing border-box;
      border-bottom 1px solid #F0F0F0;
  .tabbarContent
    width 100%;
    height calc(100vh - 150px);;
    box-sizing border-box;
    .placeholderMap
      width 100%;
      height 150px;
    .noScroll
      overflow-y hidden;
    .canScroll
      overflow-y auto
    .scrollContainer
      width 100%;
      height 100%;
      overflow-x hidden;
      scrollbar-width: none;
      &::-webkit-scrollbar
        width 0px;
</style>
<style lang="stylus">
.tabbarContent
  .el-carousel
    height 100%;
</style>
