<template>
  <div class="detailsPage" v-if="!showNoData">
    <div class="detailsPageContent" v-if="!postIsDelete">
      <div class="detailsContent">
        <transition name="fade">
          <div
            v-if="pageLoading"
            class="contentHeader"
            :style="{
              height: isScrollUp ? '40px' : '0px',
              opacity: isScrollUp ? '1' : '0',
            }"
          >
            <span
              class="iconfont_Me icon-left iconStyle1"
              @click.stop="goBack"
            ></span>
            <span
              class="iconfont_Me icon-home-fill iconStyle2"
              @click.stop="goBackToHomePage"
            ></span>
          </div>
        </transition>
        <transition name="fade">
          <div
            v-if="pageLoading"
            class="contentBox"
            ref="detailsContentBox"
            @mousewheel="detailsMousewheel"
            :style="{
              maxHeight: isScrollUp
                ? 'calc(100vh - 125px)'
                : 'calc(100vh - 85px)',
            }"
          >
            <!-- 操作部分 -->
            <CommonPopover
              class="commonPopoverStyle"
              v-if="operatingMenu.length !== 0"
              :operatingMenu="operatingMenu"
              @selectThisMenuItem="selectThisMenuItem"
            >
              <div class="commonPopoverButton">
                <img
                  :style="{ width: '16px', objectFit: 'contain' }"
                  :src="
                    require('../../../assets/images/newPersonalCenter/openPopoverIcon.png')
                  "
                />
              </div>
            </CommonPopover>
            <PostHeader
              :headerData="headerData"
              :vipStatus="headerData.vipStatus"
            ></PostHeader>
            <div class="containerForStyle">
              <DetailsContainer :postInfo="postInfo"></DetailsContainer>
              <DetailsAnnex :postInfo="postInfo"></DetailsAnnex>
              <div class="labelRow" v-if="tags.length !== 0">
                <Label
                  v-for="(item, index) in tags"
                  :key="index + item"
                  :labelContent="item"
                ></Label>
              </div>
            </div>
            <div class="actionPostBox">
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('thumbsUp')"
                placement="bottom"
              >
                <div
                  class="actionStyle changelikeStatusStyle"
                  @click.stop="changelikeStatus"
                  :style="{ background: postInfo.likeStatus ? '#FFF6F6' : '' }"
                  v-loading="likeLoading"
                  element-loading-spinner="el-icon-loading"
                  element-loading-background="#FFFFFF"
                >
                  <span
                    class="iconfont_Me iconStyle"
                    :class="
                      postInfo.likeStatus
                        ? 'icon-fabulous-fill'
                        : 'icon-fabulous'
                    "
                    :style="{ color: postInfo.likeStatus ? '#FC4C4C' : '' }"
                  ></span>
                </div>
              </el-tooltip>
              <SharePopover
                :shareConfig="shareConfig"
                @shareForMelinked="shareForMelinked"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('share')"
                  placement="bottom"
                >
                  <div class="actionStyle shareButtonStyle">
                    <span class="iconfont_Me icon-share iconStyle"></span>
                  </div>
                </el-tooltip>
              </SharePopover>
            </div>
            <div class="intervalLine" ref="intervalLine"></div>
            <CommentArea
              v-if="postInfo"
              ref="commentArea"
              :postInfo="postInfo"
              :needScroll="needScroll"
            ></CommentArea>
          </div>
        </transition>
      </div>
      <DetailsSidebar
        :postInfo="postInfo"
        :blacklistInfo="blacklistInfo"
      ></DetailsSidebar>
    </div>
    <PlaceholderMap :placeholderType="0" v-else></PlaceholderMap>
  </div>
  <div v-else>
    <div class="detailsPage no-data">
      <div class="detailsPageContent" style="flex-direction: column">
        <div class="detailsContent">
          <transition name="fade">
            <div
              class="contentHeader"
              :style="{
                height: isScrollUp ? '40px' : '0px',
                opacity: isScrollUp ? '1' : '0',
              }"
            >
              <span
                class="iconfont_Me icon-left iconStyle1"
                @click.stop="goBackToHomePage"
              ></span>
              <span
                class="iconfont_Me icon-home-fill iconStyle2"
                @click.stop="goBackToHomePage"
              ></span>
            </div>
          </transition>
          <div class="no-data-wrap">
            <img
              class="no-data-img"
              src="../../../assets/images/no-data.png"
              alt=""
            />
            <div>{{ $t("noPost") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CommonPopover from "../../components/commonPopover";
import PostHeader from "../../components/postHeader";
import DetailsContainer from "./detailsContent/detailsContainer";
import DetailsAnnex from "./detailsContent/detailsAnnex";
import Label from "../../components/label";
import CommentArea from "./detailsContent/commentArea";
import DetailsSidebar from "./detailsSidebar";
import { getPostInformation } from "@/api/newVersion/postContentDetails";
import { changelikeStatus } from "@/api/newVersion";
import SharePopover from "../../components/sharePopover";
import PlaceholderMap from "../../components/placeholderMap";
import { deletePost, shieldPost } from "@/api/newVersion/personalCenter";
import * as RESTApi from "@/api/rest";
export default {
  components: {
    PostHeader,
    DetailsContainer,
    DetailsAnnex,
    Label,
    CommentArea,
    DetailsSidebar,
    SharePopover,
    PlaceholderMap,
    CommonPopover,
  },
  data() {
    return {
      // 帖子ID
      gigId: "",
      // 帖子详情
      postInfo: {},
      // 发布者黑名单信息
      blacklistInfo: {},
      // 滚动方向监听
      scrollTop: 0,
      // 在向上滚动
      isScrollUp: true,
      // 页面加载loading
      pageLoading: false,
      // 改变喜欢帖子的状态
      likeLoading: false,
      // 帖子已被删除
      postIsDelete: false,
      // 评论区是否可以滚动
      needScroll: false,
      showNoData: false,
    };
  },
  watch: {
    // 监听帖子详情页面变化
    "$route.params.id": {
      async handler(val, old) {
        if (val !== old && this.$route.name === "postContentDetails") {
          this.gigId = this.$Base64.decode(val.replace("pai_", ""));
          this.isScrollUp = true;
          await this.getPostInformation();
        }
      },
      deep: true,
    },
    pageLoading: {
      handler(val, old) {
        if (val && val !== old) {
          this.$nextTick(() => {
            try {
              if (this.$route.params.comment) {
                if (this.$store.state.globalShare.showCommentInputInPostInfo) {
                  this.$nextTick(() => {
                    this.$refs.commentArea.$refs.tabbarContent_body.scrollIntoView(
                      true
                    );
                  });
                } else {
                  this.$nextTick(() => {
                    this.$refs.intervalLine.scrollIntoView(true);
                  });
                }
              }
            } catch (error) {}
            try {
              this.$refs.detailsContentBox.addEventListener(
                "scroll",
                this.handleScroll,
                true
              );
            } catch (error) {}
          });
        }
      },
      deep: true,
    },
  },
  computed: {
    // 气泡弹窗操作菜单
    operatingMenu() {
      let operatingMenu = [];
      // 是管理员或者是自己的帖子,显示删除图标
      if (
        this.$store.getters.userInfo.manager_role == 1 ||
        this.isSelfPublish()
      ) {
        operatingMenu.push({
          iconClassName: "icon-delet",
          operatingFn: this.deleteConfirm,
          fnType: "delete",
          tooltipContent: this.$t("delete"),
        });
      }

      // 不是管理员，也不是自己的帖子，显示投诉图标,屏蔽操作
      if (
        this.$store.getters.userInfo.manager_role != 1 &&
        !this.isSelfPublish()
      ) {
        operatingMenu.push(
          {
            iconClassName: "iconfont_Me icon-a-zu5510",
            operatingFn: this.complaintConfirm,
            fnType: "complaint",
            tooltipContent: this.$t("complaint"),
          },
          {
            iconClassName: "iconfont_Me icon-a-zu9817",
            fnType: "shield",
            tooltipContent: this.$t("shield"),
          }
        );
      }

      // // 如果是管理员，有升降序权限
      if (this.$store.getters.userInfo.manager_role == 1) {
        operatingMenu.push(
          {
            iconClassName: "el-icon-bottom",
            operatingFn: this.descendingOrder,
            fnType: "descendingOrder",
            tooltipContent: this.$t("descending"),
          },
          {
            iconClassName: "el-icon-top",
            operatingFn: this.ascendingOrder,
            fnType: "ascendingOrder",
            tooltipContent: this.$t("ascending"),
          }
        );
      }

      return operatingMenu;
    },
    // 帖子头部信息
    headerData() {
      return {
        name: this.postInfo.name,
        country:
          this.postInfo.country !== "" ? Number(this.postInfo.country) : 0,
        profilePhoto: this.postInfo.profilePhoto,
        publishTime: this.postInfo.publishTime,
        providerId: this.postInfo.providerId,
        pageViewCount: this.postInfo.pageViewCount,
        vipStatus:
          typeof this.postInfo.vipStatus == "boolean" &&
          this.postInfo.vipStatus,
      };
    },
    // 帖子标签
    tags() {
      let tags = [];
      if (this.postInfo.tags && this.postInfo.tags !== "") {
        tags = this.postInfo.tags.split(",");
      }
      return tags;
    },
    // 分享所需要的参数
    shareConfig() {
      return {
        // 分享的内容类型(1:帖子,3:用户,4:群)
        share_type: 1,
        // 分享的内容Id
        share_id: this.postInfo.gigId,
      };
    },
    // 分享内容的图片地址
    shareImageSrc() {
      let shareImageSrc = "";
      // 如果有图片附件，就去图片附件的第一张图片作为分享封面
      if (
        this.postInfo.documentType === 3 &&
        this.postInfo.attachments.length !== 0
      ) {
        shareImageSrc = this.postInfo.attachments[0].path;
      }
      return shareImageSrc;
    },
    // 分享内容的描述
    shareSummary() {
      let richDetailFromApp = [];
      // 兼容5.0.0新版本富文本数组结构数据
      if (
        typeof this.postInfo.detail === "string" &&
        this.postInfo.detail !== ""
      ) {
        if (
          this.postInfo.detail.indexOf("[") === 0 &&
          this.postInfo.detail.lastIndexOf("]") ===
            this.postInfo.detail.length - 1
        ) {
          try {
            let dataFromApp = JSON.parse(this.postInfo.detail);
            dataFromApp.forEach((element) => {
              if (element.key == "text") {
                richDetailFromApp.push(element);
              }
            });
          } catch (error) {
            console.log("解析帖子详情失败", this.postDetail);
          }
        } else {
          richDetailFromApp.push({ content: this.postInfo.detail });
        }
      }
      return richDetailFromApp.length !== 0 ? richDetailFromApp[0].content : "";
    },
  },
  async created() {
    // 如果没有带ID过来，就强制跳转到主页
    if (this.$route.params.id) {
      this.gigId = this.$Base64.decode(
        this.$route.params.id.replace("pai_", "")
      );
      await this.getPostInformation();
    } else {
      this.routeJump({ name: "home" });
    }
  },
  methods: {
    // 监听滚动事件
    detailsMousewheel() {
      try {
        if (
          this.$refs.detailsContentBox.offsetHeight +
            this.$refs.detailsContentBox.scrollTop +
            20 >=
          this.$refs.detailsContentBox.scrollHeight
        ) {
          this.needScroll = true;
        }
      } catch (error) {}
    },
    // 分享帖子信息到IM
    shareForMelinked(shareData) {
      // 重置分享信息
      let sharingDataInfo = {
        // 分享的标题
        title: shareData.shareTitle,
        // 分享展示图片
        picurl: shareData.shareImageSrc,
        // 分享展示内容
        briefInfo: shareData.summary,
        // 分享的类型（个人：people；群组：group；工作：job；帖子：service；公司：company）
        serviceType: "service",
        // 分享对象的唯一ID
        uuid: this.postInfo.gigId,
      };
      this.$store.dispatch("commitOpenSharePopup", sharingDataInfo);
    },
    // 返回主页
    goBackToHomePage() {
      this.routeJump({ name: "home" });
    },
    //返回上一页
    goBack() {
      this.$router.back();
    },
    // 滚动监听
    handleScroll() {
      // 页面滚动距顶部距离
      let scrollTop = this.$refs.detailsContentBox.scrollTop;
      let scroll = scrollTop - this.scrollTop;
      this.scrollTop = scrollTop;
      if (scroll < 0) {
        this.isScrollUp = true;
      } else {
        this.isScrollUp = false;
      }
    },
    // 开始获取帖子的详细信息
    getPostInformation() {
      this.pageLoading = false;
      return new Promise((resolve, reject) => {
        this.postInfo = {};
        this.$nextTick(async () => {
          let result = await getPostInformation(this.gigId);
          if (result.code === 200) {
            this.showNoData = false;
            this.postIsDelete = false;
            this.postInfo = result.data.data;
            this.postInfo.pageViewCount =
              Number(this.postInfo.pageViewCount) + 1;
            if (this.postInfo.isDelete == 3) {
              this.showNoData = true;
            }
            await this.getBlacklistStatus();
          } else if (result.code === 501 && result.domain === "helogig") {
            this.postIsDelete = true;
            this.$message({
              type: "error",
              message: result.message,
            });
          } else if (result.code === 504) {
            this.showNoData = true;
          } else {
            this.postIsDelete = true;
            this.$message({
              type: "error",
              message: result.message,
            });
          }
          this.$nextTick(() => {
            this.pageLoading = true;
            resolve("success");
          });
        });
      });
    },
    // 获取帖子发布者对应的黑名单状态
    async getBlacklistStatus() {
      let result = await RESTApi.getBlacklistStatus([this.postInfo.providerId]);
      if (result.code == 200) {
        try {
          this.blacklistInfo = result.data.data[0];
        } catch (error) {
          console.log(error);
        }
      } else {
        // this.$message({
        //   type: "error",
        //   message: result.message
        // })
      }
    },
    // 改变喜欢状态
    async changelikeStatus() {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      let params = {
        userId: this.$store.getters.userInfo.id,
        language: localStorage.getItem("langCode"),
        action: !this.postInfo.likeStatus,
        type: 1,
      };
      this.likeLoading = true;
      let result = await changelikeStatus(this.postInfo.gigId, params);
      if (result.code === 201) {
        this.$set(this.postInfo, "likeStatus", !this.postInfo.likeStatus);
        this.$set(
          this.postInfo,
          "likeCount",
          this.postInfo.likeStatus
            ? (Number(this.postInfo.likeCount) + 1).toString()
            : (Number(this.postInfo.likeCount) - 1).toString()
        );
        let likeProvider = {
          country: this.$store.getters.userInfo.country,
          iCanDo: "",
          providerId: this.$store.getters.userInfo.id,
          tag: "",
          time: "",
          userAvatar: this.$store.getters.userInfo.avatar,
          userName: this.$store.getters.name,
        };
        let likeProviders = this.postInfo.likeProviders;
        if (
          likeProviders.length !== 0 &&
          likeProviders.some(
            (item) => item.providerId === this.$store.getters.userInfo.id
          )
        ) {
          // 如果当前列表中有本地这个用户，就删除这个用户，否则就加入这个用户
          likeProviders = likeProviders.filter(
            (e) => e.providerId !== this.$store.getters.userInfo.id
          );
        } else {
          likeProviders.unshift(likeProvider);
        }
        this.$set(this.postInfo, "likeProviders", likeProviders);
        // 将点赞结果存入状态管理器中
        let likedRecord = {
          likeItemId: this.postInfo.gigId,
          likeCount: this.postInfo.likeCount,
          likeStatus: this.postInfo.likeStatus,
        };
        this.$store.dispatch("commitSetAlreadyLikedRecords", likedRecord);
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
      this.$forceUpdate();
      this.$nextTick(() => {
        this.likeLoading = false;
      });
    },
    // 判断是否是自己发布的帖子
    isSelfPublish() {
      return this.$store.getters.userInfo.id == this.postInfo.providerId;
    },
    selectThisMenuItem(_, item) {
      switch (item.fnType) {
        case "complaint":
          this.complaintConfirm();
          break;
        case "delete":
          this.deleteConfirm();
          break;
        case "shield":
          this.shieldConfirm();
          break;
        case "descendingOrder":
          this.descendingOrder();
          break;
        case "ascendingOrder":
          this.ascendingOrder();
          break;
      }
    },
    // 降序
    descendingOrder() {
      this.$store.commit("setDescendingOrderPostId", this.postInfo.gigId);
      this.$store.commit("setDescendingOrderPopoverVisible", true);
    },
    // 升序
    ascendingOrder() {
      this.$store.commit("setAscendingOrderPostId", this.postInfo.gigId);
      this.$store.commit("setAscendingOrderPopoverVisible", true);
    },
    //確認刪除
    deleteConfirm() {
      if (this.isSelfPublish()) {
        this.confirm(null, null, "el-icon-delete", this.deletePost);
      } else {
        this.$store.commit("setDeleteType", "post");
        this.$store.commit("setDeletePostId", this.postInfo.gigId);
        this.$store.commit("setDeleteDialogVisible", true);
      }
    },
    //确认屏蔽
    shieldConfirm() {
      this.confirm(null, this.$t("blockThisPost"), null, () => {
        this.shieldPost();
      });
    },
    async shieldPost() {
      let result = await shieldPost(this.postInfo.gigId);
      if (result.code == 200) {
        if (result) {
          this.$store.commit("setReloadPage", false);
          this.$nextTick((e) => {
            this.$store.commit("setDescendingOrderStatus", Date.now());
            this.$store.commit("setReloadPage", true);
            this.jumpPage({ name: "home" });
          });
        }
        this.$message({
          message: "success",
          type: "success",
        });
      }
    },
    // 删除自己的帖子
    async deletePost() {
      let params = {
        helogigId: this.postInfo.gigId,
        userId: this.$store.getters.userInfo.id,
      };
      let result = await deletePost(params);
      if (result) {
        this.$store.commit("setDeleteType", "post");
        this.$store.commit("setDeletePostId", this.postInfo.gigId);
        this.$store.commit("setDeleteStatus", true);
        this.$router.back();
      }
    },
    // 投诉处理
    complaintConfirm() {
      this.$store.commit("setComplaintType", 1);
      this.$store.commit("setComplaintId", this.postInfo.gigId);
      this.$store.commit("setComplaintDialogVisible", true);
    },
  },
};
</script>
<style lang="stylus" scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.detailsPage {
  width: 100vw;
  height: calc(100vh - 60px);
  box-sizing: border-box;
  background: #F7F7F7;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;

  .detailsPageContent {
    width: 1160px;
    height: 100%;
    overflow: hidden;
    display: flex;
    margin-top: 15px;
    align-items: flex-start;
    justify-content: space-between;

    .detailsContent {
      width: 866px;
      max-height: 100%;
      background: #ffffff;

      .contentHeader {
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        border-bottom: 1px solid #F0F0F0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        transition: height 0.3s, opacity 0.3s;

        .iconStyle1 {
          font-size: 22px;
          color: #333333;
          margin-right: 8px;
          cursor: pointer;
        }

        .iconStyle2 {
          font-size: 22px;
          color: #8F8F8F;
          cursor: pointer;
        }
      }

      .contentBox {
        width: 100%;
        padding: 24px 30px;
        overflow-x: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        transition: height 0.3s;
        scrollbar-width: none;
        position: relative;

        &::-webkit-scrollbar {
          width: 0px;
        }

        .opration {
          position: absolute;
          right: 30px;
          top: 24px;
        }

        .commonPopoverStyle {
          position: absolute;
          top: 20px;
          right: 30px;
          z-index: 2;

          .commonPopoverButton {
            width: 24px;
            height: 20px;
            cursor: pointer;
            border-radius: 4px;
            background: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .containerForStyle {
          width: 100%;
          box-sizing: border-box;
          padding-left: 66px;

          .labelRow {
            width: 100%;
            height: auto;
            line-height: 40px;
            overflow: hidden;
          }
        }

        .actionPostBox {
          height: 60px;
          width: 100%;
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: center;

          .actionStyle {
            height: 60px;
            width: 60px;
            border-radius: 50%;
            background: #F7F7F7;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.3s, background 0.3s;

            .iconStyle {
              color: #666666;
              font-size: 32px;
              transition: color 0.3s;
            }

            &:active {
              transform: scale(1.1);
            }
          }

          .changelikeStatusStyle {
            margin-right: 25px;

            &:hover {
              background: #FFF6F6;

              .iconStyle {
                color: #FC4C4C;
              }
            }
          }

          .shareButtonStyle {
            &:hover {
              background: #F2FCF8;

              .iconStyle {
                color: #33CC66;
              }
            }
          }
        }

        .intervalLine {
          width: 100%;
          height: 1px;
          margin-top: 30px;
          background: #F0F0F0;
        }
      }
    }
  }
}
</style>
<style lang="stylus">
.changelikeStatusStyle {
  .el-loading-mask {
    .el-loading-spinner {
      margin-top: 0px;
      transform: translate3d(0, -50%, 0);
    }
  }
}

.no-data {
  .detailsPageContent {
    align-items: center !important;

    .detailsContent {
      .contentHeader {
        border-bottom: 10px solid #f7f7f7 !important;
      }
    }
  }

  .no-data-wrap {
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 442px;
    flex-direction: column;
    font-size: 14px;
    color: #ccc;

    .no-data-img {
      width: 95px;
      height: 62px;
      margin-bottom: 10px;
    }
  }
}
</style>
