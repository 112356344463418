var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "commentArea" },
    [
      _c("CommentInputBox", {
        attrs: {
          showAvatar: true,
          sendLoading: _vm.sendLoading,
          withPictures: true
        },
        on: { triggerSubmit: _vm.triggerSubmit }
      }),
      _c("div", { staticClass: "tabbarContainer" }, [
        _vm.needShowTab
          ? _c(
              "div",
              { staticClass: "commentTabbar" },
              [
                _c("Tabbar", {
                  attrs: { tabbars: _vm.tabbars },
                  on: { tabActive: _vm.tabActive }
                })
              ],
              1
            )
          : _vm._e(),
        _vm.needShowTab
          ? _c("div", { staticClass: "bottomBorderBox" })
          : _vm._e()
      ]),
      _vm.reloadFlag
        ? _c(
            "div",
            { ref: "tabbarContent_body", staticClass: "tabbarContent" },
            [
              _c(
                "el-carousel",
                {
                  ref: "tabbarContent",
                  attrs: {
                    height: _vm.carouselHeight,
                    "initial-index": _vm.tabIndex,
                    "indicator-position": "none",
                    arrow: "never",
                    autoplay: false,
                    loop: false
                  }
                },
                _vm._l(_vm.tabbars, function(item, index) {
                  return _c(
                    "el-carousel-item",
                    { key: item.className },
                    [
                      _vm.tabbars[index].countText !== "0"
                        ? [
                            !_vm.needScroll
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "scroll",
                                        rawName: "v-scroll",
                                        value: {
                                          distance: 70,
                                          onBottom:
                                            _vm.loadMoreRecentCommunicationsData
                                        },
                                        expression:
                                          "{\n              distance: 70,\n              onBottom: loadMoreRecentCommunicationsData,\n            }"
                                      }
                                    ],
                                    staticClass: "scrollContainer noScroll"
                                  },
                                  [
                                    _c(item.componentName, {
                                      ref: item.componentName,
                                      refInFor: true,
                                      tag: "div",
                                      attrs: {
                                        replyInTheComponent: true,
                                        likeProviders:
                                          _vm.postInfo.likeProviders,
                                        getCommentsParam: _vm.getCommentsParam,
                                        showNewComment: true,
                                        openCommentInput: _vm.openCommentInput
                                      },
                                      on: {
                                        getCommentCount: _vm.getCommentCount
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "scroll",
                                    rawName: "v-scroll",
                                    value: {
                                      distance: 70,
                                      onBottom:
                                        _vm.loadMoreRecentCommunicationsData
                                    },
                                    expression:
                                      "{\n              distance: 70,\n              onBottom: loadMoreRecentCommunicationsData,\n            }"
                                  }
                                ],
                                staticClass: "scrollContainer canScroll",
                                style: { opacity: _vm.needScroll ? 1 : 0 }
                              },
                              [
                                _c(item.componentName, {
                                  ref: item.componentName,
                                  refInFor: true,
                                  tag: "div",
                                  attrs: {
                                    replyInTheComponent: true,
                                    likeProviders: _vm.postInfo.likeProviders,
                                    getCommentsParam: _vm.getCommentsParam,
                                    showNewComment: true
                                  },
                                  on: { getCommentCount: _vm.getCommentCount }
                                })
                              ],
                              1
                            )
                          ]
                        : _c(
                            "div",
                            { staticClass: "placeholderMap" },
                            [
                              _c("PlaceholderMap", {
                                attrs: { placeholderType: 0 }
                              })
                            ],
                            1
                          )
                    ],
                    2
                  )
                }),
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }