<template>
  <div class="recommendedArea" v-if="recommendedPosts.length !== 0">
    <recommendedItem v-for="item in recommendedPosts" :key="item.gigId" :postInfo="item"></recommendedItem>
  </div>
</template>
<script>
import recommendedItem from "./recommendedItem";
import { getRecommendedList } from "@/api/newVersion/postContentDetails";
export default {
  components: {
    recommendedItem,
  },
  props: {
    postInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    'postInfo.gigId': {
      handler(val,old) {
        if(val && val !== old) {
          this.page = 1;
          this.recommendedPosts = [];
          this.getRecommendedPost();
        }
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      recommendedPosts: [],
      page: 1,
    }
  },
  methods: {
    // 加载更多推荐帖子
    async loadRecommendedData() {
      this.page++;
      await this.getRecommendedPost();
    },
    // 获取对应的推荐帖子
    getRecommendedPost() {
      return new Promise( async (resolve, reject) => {
        let params = {
          documentType: this.postInfo.documentType,
          page: this.page,
          pageSize :20,
          gigId: this.postInfo.gigId,
        };
        let result =  await getRecommendedList(this.postInfo.gigId,params);
        if(result.code === 200) {
          if(result.data.data.length !== 0) {
            this.recommendedPosts = this.recommendedPosts.concat(result.data.data);
          } else {
            this.page = this.page > 1? this.page - 1: this.page
          }
          this.$forceUpdate();
        } else {
          this.page = this.page > 1? this.page - 1: this.page
          this.$message({
            type: "error",
            message: result.message
          })
        }
        this.$nextTick(() => {
          resolve('success')
        })
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
  .recommendedArea
    width 100%;
    min-height 0;
    padding 10px 15px;
    box-sizing border-box;
    background #ffffff;
    overflow hidden;

</style>