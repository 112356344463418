<template>
  <div
    class="detailsSidebar"
    v-infinite-scroll="loadRecommendedData"
    infinite-scroll-disabled="loadRecommendedDisabled"
    infinite-scroll-distance="10"
    infinite-scroll-immediate-check="false"
  >
    <div class="businessCardBox" v-loading="businessCardLoading">
      <BusinessCard
        :businessCardDetails="businessCardDetails"
        :businessCardSize="70"
      ></BusinessCard>
      <div class="businessCardFooter">
        <el-tooltip
          class="item"
          effect="dark"
          :content="$t('chat')"
          placement="top"
        >
          <button
            class="buttonStyle leftButton greenButtonStyle"
            @click.stop="startChatting"
          >
            <span class="iconfont_Me icon-information-fill iconStyle1"></span>
          </button>
        </el-tooltip>
        <template v-if="!isSelfPost">
          <button
            class="buttonStyle rightButton grayButtonStyle"
            @click.stop="modifyFriendInformation"
            v-if="!postInfo.friendStatus"
            :style="{
              background:
                blacklistButtonStatus && !postInfo.friendStatus
                  ? '#B3B3B3'
                  : '',
              cursor:
                blacklistButtonStatus && !postInfo.friendStatus
                  ? 'not-allowed'
                  : '',
            }"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('addFrirend')"
              placement="top"
            >
              <span class="iconfont_Me icon-Adduser-fill iconStyle2"></span>
            </el-tooltip>
          </button>
          <button
            class="buttonStyle rightButton grayButtonStyle"
            @click.stop="deleteFriend"
            v-else
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('deleteFriends')"
              placement="top"
            >
              <span class="iconfont_Me icon-deleteuser-fill iconStyle2"></span>
            </el-tooltip>
          </button>
        </template>
      </div>
    </div>
    <RecommendedArea
      :postInfo="postInfo"
      ref="recommendedArea"
    ></RecommendedArea>
  </div>
</template>
<script>
import BusinessCard from "../../../components/businessCard";
import RecommendedArea from "./recommendedArea";
import { getPersonnelBasicInformation } from "@/api/newVersion/postContentDetails";
import { deleteFriend } from "@/api/newVersion";
export default {
  components: {
    BusinessCard,
    RecommendedArea,
  },
  props: {
    // 帖子信息
    postInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 黑名单信息
    blacklistInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    "postInfo.providerId": {
      async handler(val, old) {
        if (val && val !== old) {
          this.businessCardLoading = true;
          await this.getPersonnelBasicInformation(val);
          this.businessCardLoading = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    // 添加好友按钮显示的状态
    blacklistButtonStatus() {
      let blacklistButtonStatus = false;
      try {
        blacklistButtonStatus =
          this.blacklistInfo.positiveStatus || this.blacklistInfo.reverseStatus;
      } catch (e) {
        console.log(e);
      }
      return blacklistButtonStatus;
    },
    // 根据不同的黑名单关系展示不同的提示
    blacklistMessage() {
      let blacklistMessage = "";
      try {
        // 本地用户将此用户拉入黑名单
        if (this.blacklistInfo.positiveStatus) {
          blacklistMessage = this.$t("blackListPositiveStatus");
          // 此用户将本地用户拉入黑名单
        } else if (this.blacklistInfo.reverseStatus) {
          blacklistMessage = this.$t("blackListReverseStatus");
        }
      } catch (error) {}
      return blacklistMessage;
    },
    // 判断这个帖子是不是自己本人发的帖子
    isSelfPost() {
      return this.postInfo.providerId === this.$store.getters.userInfo.id;
    },
  },
  data() {
    return {
      // 名片加载延迟
      businessCardLoading: false,
      // 名片信息
      businessCardDetails: {},
      // 添加好友弹窗状态
      modifyFriendPopFlag: false,
      // 是否可以加载更多推荐数据
      loadRecommendedDisabled: false,
    };
  },
  methods: {
    // 加载更多推荐数据
    async loadRecommendedData() {
      this.loadRecommendedDisabled = true;
      await this.$refs.recommendedArea.loadRecommendedData();
      this.loadRecommendedDisabled = false;
    },
    // 开始聊天
    async startChatting() {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      this.chatWithAuthor(
        {
          thumb: this.businessCardDetails.avatar,
          name: this.businessCardDetails.userName,
          id: this.businessCardDetails.providerId,
        },
        "user"
      );
    },
    // 删除好友
    async deleteFriend() {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      this.$confirm("", "", {
        confirmButtonText: " ",
        cancelButtonText: " ",
        type: "warning",
        center: true,
        iconClass: "el-icon-delete",
        customClass: "my-confirm",
        cancelButtonClass: "el-icon-close my-confirm-btn",
        confirmButtonClass: "el-icon-check my-confirm-btn",
      })
        .then(async () => {
          let params = {
            userId: this.$store.getters.userInfo.id,
            friendId: this.businessCardDetails.providerId,
            language: localStorage.getItem("langCode"),
          };
          let result = await deleteFriend(params);
          if (result.code) {
            this.postInfo.friendStatus = !this.postInfo.friendStatus;
          } else {
            this.$message({
              type: "error",
              message: result.message,
            });
          }
        })
        .catch((e) => {});
    },
    // 添加好友关系
    modifyFriendInformation() {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      // 只拦截可添加好友状态
      if (this.blacklistButtonStatus && !this.postInfo.friendStatus) {
        this.$message({
          type: "error",
          message: this.blacklistMessage,
        });
        return;
      }
      let userInfoForAddFriend = {
        id: this.businessCardDetails.providerId,
        avatar: this.businessCardDetails.avatar,
        name: this.businessCardDetails.userName,
        vipStatus: this.businessCardDetails.vipStatus,
      };
      this.$store.dispatch("commitOpenAddFriendPopup", userInfoForAddFriend);
    },
    // 请求用户详细信息
    getPersonnelBasicInformation(providerId) {
      return new Promise(async (resolve, reject) => {
        let result = await getPersonnelBasicInformation(providerId);
        if (result.code == 200) {
          let tags = [];
          if (result.data.data.skills && result.data.data.skills !== "") {
            tags = result.data.data.skills.split(",");
          }
          let userIntro = "";
          if (result.data.data.intro && result.data.data.intro !== "") {
            let introData = JSON.parse(
              result.data.data.intro.replace(/\n/g, "")
            );
            introData.forEach((item) => {
              if (
                item.key === "text" &&
                item.content !== "" &&
                item.content.replace(/\n/g, "") !== ""
              ) {
                userIntro += item.content;
              }
            });
          } else if (
            result.data.data &&
            result.data.data.aboutMe &&
            result.data.data.aboutMe !== ""
          ) {
            userIntro = result.data.data.aboutMe;
          }
          this.businessCardDetails = {
            // 用户头像
            avatar: result.data.data.profilePhoto,
            // 用户姓名
            userName: result.data.data.name,
            // 用户性别
            gender: result.data.data.gender,
            // 用户ID
            providerId: result.data.data.providerId,
            // 用户国籍
            countryCode: result.data.data.country,
            // 擅长技能
            iCanDo: result.data.data.skills,
            // 自我介绍
            aboutMe: userIntro,
            // 标签集合
            tags: tags,
            vipStatus: result.data.data.vipStatus,
          };
        } else {
          this.$message({
            type: "error",
            message: result.message,
          });
        }
        this.$nextTick(() => {
          resolve("success");
        });
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.detailsSidebar
  width 278px;
  height 100%;
  overflow-x hidden;
  overflow-y auto;
  scrollbar-width: none;
  &::-webkit-scrollbar
    width 0px;
  .businessCardBox
    width 100%;
    min-height 0;
    padding 10px 0 20px 0;
    overflow hidden;
    background #ffffff;
    flex-shrink 0;
    margin-bottom 15px;
    .businessCardFooter
      height 40px;
      width 100%;
      box-sizing border-box;
      display flex;
      align-items center;
      justify-content center;
      .buttonStyle
        height 40px;
        width 107px;
        outline none;
        border none;
        display block;
        cursor pointer;
        border-radius 6px;
        transition background .3s;
        .iconStyle1
          font-size 24px;
          color #ffffff;
        .iconStyle2
          font-size 24px;
          color #333333;
      .greenButtonStyle
        &:hover
          background #5CD685;
      .grayButtonStyle
        &:hover
          background #F0F0F0;
      .leftButton
        background #33CC66;
        margin-right 6px;
      .rightButton
        background #E6E6E6;
</style>
