<template>
  <div class="detailsContainer">
    <h3 v-if="postInfo.iCanDo !== ''" :style="{fontSize:'16px',color:'#373737',margin:'0 0 12px 0'}">{{postInfo.iCanDo}}</h3>
    <DetailsTextDisplay :postInfo="postInfo"></DetailsTextDisplay>
  </div>
</template>
<script>
import DetailsTextDisplay from "../../../components/detailsTextDisplay"
export default {
  components: {
    DetailsTextDisplay
  },
  props: {
    postInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
}
</script>
<style lang="stylus" scoped>
  .detailsContainer
    width: 100%;
    height auto;
    padding 5px 0;
    box-sizing border-box;
    overflow hidden;

</style>