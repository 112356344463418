var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.showNoData
    ? _c(
        "div",
        { staticClass: "detailsPage" },
        [
          !_vm.postIsDelete
            ? _c(
                "div",
                { staticClass: "detailsPageContent" },
                [
                  _c(
                    "div",
                    { staticClass: "detailsContent" },
                    [
                      _c("transition", { attrs: { name: "fade" } }, [
                        _vm.pageLoading
                          ? _c(
                              "div",
                              {
                                staticClass: "contentHeader",
                                style: {
                                  height: _vm.isScrollUp ? "40px" : "0px",
                                  opacity: _vm.isScrollUp ? "1" : "0"
                                }
                              },
                              [
                                _c("span", {
                                  staticClass:
                                    "iconfont_Me icon-left iconStyle1",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.goBack.apply(null, arguments)
                                    }
                                  }
                                }),
                                _c("span", {
                                  staticClass:
                                    "iconfont_Me icon-home-fill iconStyle2",
                                  on: {
                                    click: function($event) {
                                      $event.stopPropagation()
                                      return _vm.goBackToHomePage.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                })
                              ]
                            )
                          : _vm._e()
                      ]),
                      _c("transition", { attrs: { name: "fade" } }, [
                        _vm.pageLoading
                          ? _c(
                              "div",
                              {
                                ref: "detailsContentBox",
                                staticClass: "contentBox",
                                style: {
                                  maxHeight: _vm.isScrollUp
                                    ? "calc(100vh - 125px)"
                                    : "calc(100vh - 85px)"
                                },
                                on: { mousewheel: _vm.detailsMousewheel }
                              },
                              [
                                _vm.operatingMenu.length !== 0
                                  ? _c(
                                      "CommonPopover",
                                      {
                                        staticClass: "commonPopoverStyle",
                                        attrs: {
                                          operatingMenu: _vm.operatingMenu
                                        },
                                        on: {
                                          selectThisMenuItem:
                                            _vm.selectThisMenuItem
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "commonPopoverButton"
                                          },
                                          [
                                            _c("img", {
                                              style: {
                                                width: "16px",
                                                objectFit: "contain"
                                              },
                                              attrs: {
                                                src: require("../../../assets/images/newPersonalCenter/openPopoverIcon.png")
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c("PostHeader", {
                                  attrs: {
                                    headerData: _vm.headerData,
                                    vipStatus: _vm.headerData.vipStatus
                                  }
                                }),
                                _c(
                                  "div",
                                  { staticClass: "containerForStyle" },
                                  [
                                    _c("DetailsContainer", {
                                      attrs: { postInfo: _vm.postInfo }
                                    }),
                                    _c("DetailsAnnex", {
                                      attrs: { postInfo: _vm.postInfo }
                                    }),
                                    _vm.tags.length !== 0
                                      ? _c(
                                          "div",
                                          { staticClass: "labelRow" },
                                          _vm._l(_vm.tags, function(
                                            item,
                                            index
                                          ) {
                                            return _c("Label", {
                                              key: index + item,
                                              attrs: { labelContent: item }
                                            })
                                          }),
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "actionPostBox" },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "item",
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.$t("thumbsUp"),
                                          placement: "bottom"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "loading",
                                                rawName: "v-loading",
                                                value: _vm.likeLoading,
                                                expression: "likeLoading"
                                              }
                                            ],
                                            staticClass:
                                              "actionStyle changelikeStatusStyle",
                                            style: {
                                              background: _vm.postInfo
                                                .likeStatus
                                                ? "#FFF6F6"
                                                : ""
                                            },
                                            attrs: {
                                              "element-loading-spinner":
                                                "el-icon-loading",
                                              "element-loading-background":
                                                "#FFFFFF"
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.changelikeStatus.apply(
                                                  null,
                                                  arguments
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("span", {
                                              staticClass:
                                                "iconfont_Me iconStyle",
                                              class: _vm.postInfo.likeStatus
                                                ? "icon-fabulous-fill"
                                                : "icon-fabulous",
                                              style: {
                                                color: _vm.postInfo.likeStatus
                                                  ? "#FC4C4C"
                                                  : ""
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "SharePopover",
                                      {
                                        attrs: { shareConfig: _vm.shareConfig },
                                        on: {
                                          shareForMelinked: _vm.shareForMelinked
                                        }
                                      },
                                      [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "item",
                                            attrs: {
                                              effect: "dark",
                                              content: _vm.$t("share"),
                                              placement: "bottom"
                                            }
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "actionStyle shareButtonStyle"
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "iconfont_Me icon-share iconStyle"
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("div", {
                                  ref: "intervalLine",
                                  staticClass: "intervalLine"
                                }),
                                _vm.postInfo
                                  ? _c("CommentArea", {
                                      ref: "commentArea",
                                      attrs: {
                                        postInfo: _vm.postInfo,
                                        needScroll: _vm.needScroll
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ],
                    1
                  ),
                  _c("DetailsSidebar", {
                    attrs: {
                      postInfo: _vm.postInfo,
                      blacklistInfo: _vm.blacklistInfo
                    }
                  })
                ],
                1
              )
            : _c("PlaceholderMap", { attrs: { placeholderType: 0 } })
        ],
        1
      )
    : _c("div", [
        _c("div", { staticClass: "detailsPage no-data" }, [
          _c(
            "div",
            {
              staticClass: "detailsPageContent",
              staticStyle: { "flex-direction": "column" }
            },
            [
              _c(
                "div",
                { staticClass: "detailsContent" },
                [
                  _c("transition", { attrs: { name: "fade" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "contentHeader",
                        style: {
                          height: _vm.isScrollUp ? "40px" : "0px",
                          opacity: _vm.isScrollUp ? "1" : "0"
                        }
                      },
                      [
                        _c("span", {
                          staticClass: "iconfont_Me icon-left iconStyle1",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.goBackToHomePage.apply(null, arguments)
                            }
                          }
                        }),
                        _c("span", {
                          staticClass: "iconfont_Me icon-home-fill iconStyle2",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.goBackToHomePage.apply(null, arguments)
                            }
                          }
                        })
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "no-data-wrap" }, [
                    _c("img", {
                      staticClass: "no-data-img",
                      attrs: {
                        src: require("../../../assets/images/no-data.png"),
                        alt: ""
                      }
                    }),
                    _c("div", [_vm._v(_vm._s(_vm.$t("noPost")))])
                  ])
                ],
                1
              )
            ]
          )
        ])
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }