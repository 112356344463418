var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detailsContainer" },
    [
      _vm.postInfo.iCanDo !== ""
        ? _c(
            "h3",
            {
              style: {
                fontSize: "16px",
                color: "#373737",
                margin: "0 0 12px 0"
              }
            },
            [_vm._v(_vm._s(_vm.postInfo.iCanDo))]
          )
        : _vm._e(),
      _c("DetailsTextDisplay", { attrs: { postInfo: _vm.postInfo } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }