var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.recommendedPosts.length !== 0
    ? _c(
        "div",
        { staticClass: "recommendedArea" },
        _vm._l(_vm.recommendedPosts, function(item) {
          return _c("recommendedItem", {
            key: item.gigId,
            attrs: { postInfo: item }
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }