var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "recommendedItem", on: { click: _vm.jumpToPage } },
    [
      _c("PostHeader", {
        attrs: {
          headerData: _vm.headerData,
          vipStatus: _vm.headerData.vipStatus
        }
      }),
      _c(
        "div",
        { staticClass: "postContentBox" },
        [
          _vm.postInfo.iCanDo !== ""
            ? _c("h3", { staticClass: "postTitleContainer" }, [
                _vm._v(_vm._s(_vm.postInfo.iCanDo))
              ])
            : _vm._e(),
          _c("TextDisplay", {
            attrs: { postDetail: _vm.postInfo.detail, fontSize: "12px" },
            on: { jumpToPage: _vm.jumpToPage }
          })
        ],
        1
      ),
      _vm.postInfo.attachments.length !== 0
        ? _c(
            "div",
            { staticClass: "postAnnexBox" },
            [
              _vm.postInfo.documentType !== 0
                ? _c("DetailsAnnex", {
                    attrs: { isFromRecommended: true, postInfo: _vm.postInfo }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "postlabelBox" },
        _vm._l(_vm.tags, function(item, index) {
          return _c("Label", {
            key: index + item,
            attrs: { labelContent: item }
          })
        }),
        1
      ),
      _c("div", { staticClass: "postFooter" }, [
        _c("div", { staticClass: "footerInner" }, [
          _c("div", { staticClass: "innerLeft" }, [
            _vm._m(0),
            _vm._v(
              "\n        " +
                _vm._s(_vm.handleNewVersionCount(_vm.postInfo.commentCount)) +
                "\n      "
            )
          ]),
          _c("div", { staticClass: "innerCenter" }, [
            _c(
              "div",
              {
                staticClass: "innerIcon",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.changelikeStatus.apply(null, arguments)
                  }
                }
              },
              [
                _c("span", {
                  staticClass: "iconfont_Me iconStyle",
                  class: _vm.postInfo.likeStatus
                    ? "icon-fabulous-fill"
                    : "icon-fabulous",
                  style: { color: _vm.postInfo.likeStatus ? "#FC4C4C" : "" }
                })
              ]
            ),
            _vm._v(
              "\n        " +
                _vm._s(_vm.handleNewVersionCount(_vm.postInfo.likeCount)) +
                "\n      "
            )
          ]),
          _vm._m(1)
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "innerIcon" }, [
      _c("span", { staticClass: "iconfont_Me icon-information iconStyle" })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "innerRight" }, [
      _c("div", { staticClass: "innerIcon" }, [
        _c("span", { staticClass: "iconfont_Me icon-share iconStyle" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }