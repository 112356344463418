var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "infinite-scroll",
          rawName: "v-infinite-scroll",
          value: _vm.loadRecommendedData,
          expression: "loadRecommendedData"
        }
      ],
      staticClass: "detailsSidebar",
      attrs: {
        "infinite-scroll-disabled": "loadRecommendedDisabled",
        "infinite-scroll-distance": "10",
        "infinite-scroll-immediate-check": "false"
      }
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.businessCardLoading,
              expression: "businessCardLoading"
            }
          ],
          staticClass: "businessCardBox"
        },
        [
          _c("BusinessCard", {
            attrs: {
              businessCardDetails: _vm.businessCardDetails,
              businessCardSize: 70
            }
          }),
          _c(
            "div",
            { staticClass: "businessCardFooter" },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: _vm.$t("chat"),
                    placement: "top"
                  }
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "buttonStyle leftButton greenButtonStyle",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.startChatting.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("span", {
                        staticClass:
                          "iconfont_Me icon-information-fill iconStyle1"
                      })
                    ]
                  )
                ]
              ),
              !_vm.isSelfPost
                ? [
                    !_vm.postInfo.friendStatus
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "buttonStyle rightButton grayButtonStyle",
                            style: {
                              background:
                                _vm.blacklistButtonStatus &&
                                !_vm.postInfo.friendStatus
                                  ? "#B3B3B3"
                                  : "",
                              cursor:
                                _vm.blacklistButtonStatus &&
                                !_vm.postInfo.friendStatus
                                  ? "not-allowed"
                                  : ""
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.modifyFriendInformation.apply(
                                  null,
                                  arguments
                                )
                              }
                            }
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("addFrirend"),
                                  placement: "top"
                                }
                              },
                              [
                                _c("span", {
                                  staticClass:
                                    "iconfont_Me icon-Adduser-fill iconStyle2"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      : _c(
                          "button",
                          {
                            staticClass:
                              "buttonStyle rightButton grayButtonStyle",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.deleteFriend.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: _vm.$t("deleteFriends"),
                                  placement: "top"
                                }
                              },
                              [
                                _c("span", {
                                  staticClass:
                                    "iconfont_Me icon-deleteuser-fill iconStyle2"
                                })
                              ]
                            )
                          ],
                          1
                        )
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _c("RecommendedArea", {
        ref: "recommendedArea",
        attrs: { postInfo: _vm.postInfo }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }