<template>
  <div class="recommendedItem" @click="jumpToPage">
    <PostHeader :headerData="headerData" :vipStatus="headerData.vipStatus"></PostHeader>
    <div class="postContentBox">
      <h3 class="postTitleContainer" v-if="postInfo.iCanDo !== ''">{{postInfo.iCanDo}}</h3>
      <TextDisplay :postDetail="postInfo.detail" @jumpToPage="jumpToPage" :fontSize="'12px'"></TextDisplay>
    </div>
    <div class="postAnnexBox" v-if="postInfo.attachments.length !== 0">
      <DetailsAnnex :isFromRecommended="true" :postInfo="postInfo" v-if="postInfo.documentType !== 0"></DetailsAnnex>
    </div>
    <div class="postlabelBox">
      <Label v-for="(item,index) in tags" :key="index+item" :labelContent="item"></Label>
    </div>
    <div class="postFooter">
      <div class="footerInner">
        <div class="innerLeft">
          <div class="innerIcon">
            <span class="iconfont_Me icon-information iconStyle"></span>
          </div>
          {{handleNewVersionCount(postInfo.commentCount)}}
        </div>
        <div class="innerCenter">
          <div class="innerIcon" @click.stop="changelikeStatus">
            <span class="iconfont_Me iconStyle" :class="postInfo.likeStatus?'icon-fabulous-fill':'icon-fabulous'"
              :style="{color:postInfo.likeStatus?'#FC4C4C':''}"></span>
          </div>
          {{handleNewVersionCount(postInfo.likeCount)}}
        </div>
        <div class="innerRight">
          <div class="innerIcon">
            <span class="iconfont_Me icon-share iconStyle"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PostHeader from "../../../components/postHeader";
import Label from "../../../components/label";
import TextDisplay from "../../../components/textDisplay";
import { changelikeStatus } from "@/api/newVersion";
import DetailsAnnex from "../detailsContent/detailsAnnex";
export default {
  components: {
    PostHeader,
    Label,
    TextDisplay,
    DetailsAnnex
  },
  props: {
    postInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    // 帖子头部信息
    headerData() {
      return {
        name: this.postInfo.name,
        country: this.postInfo.country !== '' ? Number(this.postInfo.country) : 0,
        profilePhoto: this.postInfo.profilePhoto,
        publishTime: this.postInfo.publishTime,
        providerId: this.postInfo.providerId,
        pageViewCount: this.postInfo.pageViewCount,
        vipStatus: this.postInfo.vipStatus
      }
    },
    // 帖子标签
    tags() {
      let tags = [];
      if (this.postInfo.tags && this.postInfo.tags !== '') {
        tags = this.postInfo.tags.split(",")
      }
      return tags
    },
  },
  methods: {
    // 跳转详情
    jumpToPage() {
      this.routeJump({ name: 'postContentDetails', params: { id: this.postInfo.gigId } })
    },
    // 点击
    async changelikeStatus() {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      let params = {
        userId: this.$store.getters.userInfo.id,
        language: localStorage.getItem("langCode"),
        action: !this.postInfo.likeStatus,
        type: 1
      };
      let result = await changelikeStatus(this.postInfo.gigId, params);
      if (result.code === 201) {
        this.postInfo.likeStatus = !this.postInfo.likeStatus;
        this.postInfo.likeStatus ? this.postInfo.likeCount++ : this.postInfo.likeCount--;
        // 将点赞结果存入状态管理器中
        let likedRecord = {
          likeItemId: this.postInfo.gigId,
          likeCount: this.postInfo.likeCount,
          likeStatus: this.postInfo.likeStatus
        }
        this.$store.dispatch("commitSetAlreadyLikedRecords", likedRecord);
      } else {
        this.$message({
          type: "error",
          message: result.message
        })
      }
    },
    // 跳转详情
    jumpToPage() {
      this.routeJump({ name: 'postContentDetails', params: { id: this.postInfo.gigId } })
    },
  }
}
</script>
<style lang="stylus" scoped>
  .recommendedItem
    width 100%;
    border-bottom 1px solid #E6E6E6;
    padding 20px 0;
    cursor pointer;
    user-select none;
    &:first-child
      padding-top 0;
    .postContentBox
      width 100%;
      line-height 20px;
      color #333333;
      font-size 12px;
      overflow hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      margin 10px 0;
      .postTitleContainer
        font-size 14px;
        color #373737;
        margin 12px 0 0 0;
        overflow hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    .postAnnexBox
      width 100%;
    .postlabelBox
      width 100%;
      padding 0 15px;
      text-align left;
      box-sizing border-box;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      line-height 36px;
    .postFooter
      width 100%;
      height 24px;
      margin-top 10px;
      display flex;
      align-items center;
      justify-content center;
      .footerInner
        width 100%;
        height 10px;
        padding 0 10px;
        box-sizing border-box;
        display flex;
        align-items center;
        justify-content space-between;
        font-size 12px;
        color #8F8F8F;
        .innerIcon
          width 22px;
          height 22px;
          overflow hidden;
          .iconStyle
            color #666666;
            font-size 20px;
        .innerLeft
          height 24px;
          display flex;
          align-items center;
          justify-content center;
        .innerCenter
          height 100%;
          width 94px;
          box-sizing border-box;
          border-left 1px solid #CCCCCC;
          border-right 1px solid #CCCCCC;
          display flex;
          align-items center;
          justify-content center;
        .innerRight
          height 24px;

</style>